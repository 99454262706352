<template>
  <div class="focus">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "Focus",
};
</script>
<style lang="scss" scoped>
.focus {
  width: 100%;
}
</style>
